.display {
    background: #fff;
    height: 95%;
    width: 50%;
}

.navigation {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 40px;
    width: 70px;
    height: 100vh;
    border-radius: 10px;
    box-sizing: initial;
    border-left: 5px solid #1C4A57;
    background: #1C4A57;
    transition: width 1s;
    overflow-x: hidden;
    display: flex;
}

.navigation .first-col {
    width: 70px;
    height: 100%;
}

.navigation .second-col {
    width: -webkit-fill-available;
    background: #275B6A;
}

.navigation.active {
    width: 300px;
}

.navigation ul {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    padding-left: 0px;
}

.navigation ul li {
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

/**/
.navigation ul li.active {
    background: #fff;
}

.navigation ul.icon-list li {
    width: auto;
}

.navigation .icon-list {
    min-width: 70px;
    width: 70px;
}

.navigation ul.icon-list li.active {
    background: #1C4A57 !important;
}

.navigation ul li {
    padding: 10px;
}

.navigation .second-col ul li {
    width: -webkit-fill-available;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 10PX;
}

.navigation ul.icon-list li.active a {
    background: #fff;
}

/**/

.navigation ul li b:nth-child(1) {
    position: absolute;
    top: -20px;
    height: 20px;
    width: 100%;
    background: #fff;
    display: none;
}

.navigation ul li b:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 45px;
    background: #275B6A;
}

.navigation ul li b:nth-child(2) {
    position: absolute;
    bottom: -20px;
    height: 20px;
    width: 100%;
    background: #fff;
    display: none;
}

.navigation ul li b:nth-child(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 25px;
    background: #275B6A;
}

.navigation ul li.active b:nth-child(1),
.navigation ul li.active b:nth-child(2) {
    display: block;
}

.navigation ul li a {
    position: relative;
    display: block;
    width: fit-content;
    display: flex;
    text-decoration: none;
    color: #fff;
}

/**/
.navigation ul li.active a {
    color: #00bfff;
}

/**/

.navigation .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    line-height: 70px;
    text-align: center;
}

.navigation .icon ion-icon {
    font-size: 1.5em;
}

.navigation .title {
    position: relative;
    display: block;
    padding-left: 10px;
    height: 40px;
    line-height: 60px;
    white-space: normal;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.navigation .toggle {
    /* position: fixed;
    top: 40px;
    right: 384px; */
    width: 50px;
    height: 50px;
    background: #1C4A57;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle.active {
    background: #ff0000;
}

.navigation .toggle ion-icon {
    position: absolute;
    color: #fff;
    font-size: 34px;
    display: none;
}

.navigation .toggle ion-icon.open,
.navigation .toggle.active ion-icon.close {
    display: block;
}

.navigation .toggle ion-icon.close,
.navigation .toggle.active ion-icon.open {
    display: none;
}

/* icons alignment with the text */

.navigation ul li a,
.navigation ul li>span {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    display: flex;
    text-decoration: none;
    color: #fff;
}

.navigation .first-col ul li a {
    background: #275B6A;
}

.navigation ul li.active a {
    color: #084245;
}

.navigation .heading {
    margin-left: 10px;
}

@media only screen and (min-width:1024px) {
    .navigation .heading {
        font-size: 1vw;
        font-weight: 700;

    }

    .navigation .title {
        font-size: .85vw;
        font-weight: 600;

    }
}

.navigation::-webkit-scrollbar {
    width: 0;
}