@font-face {
  font-family: "Proxima-Nova";
  src: url("./fonts/Proxima_Nova_Font.otf"), format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima-Nova";
  src: url("./fonts/proximanova-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Proxima-Nova' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
div,
th,
tr,
td {
  font-family: 'Proxima-Nova' !important;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}