@tailwind base;
@tailwind components;
@tailwind utilities;



/* search bar */
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
    color: #000;
    border: 1px solid lightgrey;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #FF8F2F;
}

/* search bar end*/

/* tables design tweaks */
.table-name {
    font-weight: 700;
    color: #1A3353;
}

.ant-pagination-item-active {
    background-color: #FF8F2F !important;
    border-radius: 50% !important;
    border-color: #FCD983 !important;
}

.ant-pagination-item {
    border-radius: 50% !important;
}

/* .ant-pagination-item-link {
    border-radius: 50% !important;
    border: 1px solid #E6EBF1 !important;
} */

.ant-pagination-item-active a {
    color: #fff !important;
}

/* table th:last-child {
    text-align: center !important;
} */

@media only screen and (min-width:1024px) {
    .table-name {
        font-size: 1.5vw;
    }
}

.ant-pagination-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

/* tables design tweaks end*/
/* antd tabs*/
@media only screen and (min-width:1024px) {
    .users-tab .ant-tabs-nav-wrap {
        justify-content: flex-end;
        padding-right: 1.25rem;
    }

    .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
        padding-left: 0;
    }
}

.users-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 700;
}

.details-tab .ant-tabs-tab {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    background-color: #fff !important;
    border: none !important;
    margin-right: 2px !important;
    border-radius: 0 !important;
}

.details-tab .ant-tabs-tab-active {
    background-color: #E5E5E5 !important;
}

/* prescription upload */
.FirstTab .fileIcon {
    display: none;
}

/* prescription upload End*/


.ant-select-selection-item-content {
    display: flex;
    align-items: center;
    max-height: fit-content;
    line-height: normal;
}

.ant-pagination {
    display: flex;
    align-items: center;
}

.ant-pagination li button {
    border-radius: 50% !important;
}

.ant-pagination .ant-pagination-item-link {
    display: flex;
    align-items: center;
    height: 100%;
}

.ant-switch {
    background: rgb(191 191 191);
}



/* CKEditor */
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    min-height: 20vh;
}

/* multiple selection boxes */
.ant-select-selection-item-content,
.ant-select-item-option-content {
    display: flex !important;
    align-items: center !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #FF8F2F !important;
    background-color: #FF8F2F !important;
}

.ant-radio-wrapper .ant-radio .ant-radio-inner,
.ant-radio-wrapper .ant-radio-checked:hover {
    border: 2px solid #FF8F2F !important;
    outline: none !important;
}

.ant-radio-wrapper .ant-radio-checked::after {
    display: none;
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #FCD983 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FF8F2F !important;
    border-color: #FF8F2F !important;
}