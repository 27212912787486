.otp input {
    width: 30px !important;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #14224A;
    margin-left: 8px;
    margin-right: 8px;
    background: #FBFBFB;
    font-size: 20px;
}

.otp input+span {
    display: none;
}